import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class AdvanceSearchService {
  constructor(private http: HttpClient) { }


  getDynamicValue(apiEndPoint): Observable<any> {
    const get_url = baseApiUrl + apiEndPoint;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }


  getDetailsForDevice(name): Observable<any> {
    const get_url = baseApiUrl + 'case/getDetailsForDevice/' + name;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }


  getCustomerStatusForCase():Observable<any>{
    const get_url = baseApiUrl + "commonList/generic/custStatus";
    return this.http.get<any>(get_url);
  }
  

}
