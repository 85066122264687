import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceAreaService } from '../../pages/network-management/service-area/service/service-area.service';
import { CommonListService } from '../../services/common_list/common_list.service';
import { SearchService } from '../../services/search.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NetworkDevicesService } from '../../pages/network-management/network-device/services/network-devices.service';
import { NetworkDetailsService } from '../../pages/masters/subscriber-details/network-details/network-details.service';
import { searchSettings } from './search-settings.model';
import { SearchControlTypeEnum, SearchFilterConditionEnum, SearchFilterTypeValueEnum } from '../../core/enum/searchType.enum';
import { DynamicPojoData } from '../../core/enum/dynamicPojoData';
import { AdvanceSearchService } from './advance-search.service';
import { SearchData } from '../../../app/core/modal/search-data';
import { RecentRenewalService } from '../../pages/reports/recent-renewal.service';
declare let jQuery: any;
declare let Hammer: any;

export interface Options {
  label?: string;
  placeholder?: string;
  required?: string;
  children?: Array<FormControlObject>;
}

export interface FormControlObject {
  key: string;
  type: string;
  options: Options;
}

@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.css'],
})
export class AdvanceSearchComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() formControlValue: any;
  @Input() searchSettings: searchSettings[] = [];
  @Input() isOpened: boolean;
  @Input() preserveFilter: boolean = false;
  @Output() onApplyFilter = new EventEmitter<any>();
  @Output() onClearFilter = new EventEmitter<any>();
  @Output() toggleSearchEvent: EventEmitter<any> = new EventEmitter<any>();

  custStatusList: any[] = [{ 'name': 'Online', 'value': 'online' },
  { 'name': 'Offline', 'value': 'offline' },
  { 'name': 'Inactive', 'value': 'inactive' }
  ]

  filters: any;
  dynamicControlsArr = DynamicPojoData;
  dateConditionArr: [];
  valueArr: any;
  betweenDateFlag: boolean = false;
  custStatus: boolean = false;
  custStatusValue: any;
  snmpEnabled: boolean = false;
  filterconditionsListcontrol: any;
  frameList: any;
  slotList: any;
  portList: any;
  frameRange = [];
  form: FormGroup;
  paramValue_filterconditions = SearchFilterTypeValueEnum;
  parentHeight = 78; // example height in pixels
  childHeight = '100%'; // child container takes up all available height

  constructor(
    private searchService: SearchService,
    private fb: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private advanceSearchService: AdvanceSearchService,
    private networkDevice: NetworkDevicesService,
    private networkDetailsService: NetworkDetailsService,
    private commonService: CommonListService,
    private datepipe: DatePipe,
    private toaster: ToastrService,
    private recentRemewalService: RecentRenewalService
  ) { }

  dynamicFormConfig:any;
  ngOnInit() {
     jQuery('app-layout').addClass('chat-sidebar-container');
    this.form = this.fb.group({});

    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
            if(!obj){
              this.formControlValue.forEach((ls)=>{
                ls.dynamicFormData.paramValue = undefined;
                // ls.dynamicFormData.paramValue_filterconditions = undefined;
                ls.dynamicFormData.startDateValue = undefined;
                ls.dynamicFormData.endDateValue = undefined;
                ls.dynamicFormData.frameValue_filterconditions = undefined;
                ls.dynamicFormData.slotValue_filterconditions = undefined;
                ls.dynamicFormData.portValue_filterconditions = undefined;
              });
            }
          });       
  }

  createDynamicControl(controls) {
    for (let control of controls) {
      const newFormControl = new FormControl();
      this.form.addControl(control.dynamicFormData.paramName, newFormControl);
      this.form.addControl(control.dynamicFormData.paramName + '_filterconditions', newFormControl);
      this.filterconditionsListcontrol = control.dynamicFormData.filterconditionsList;
      if (control.dynamicFormData.paramType == 'date') {
        this.dateConditionArr = control.dynamicFormData.filterconditionsList;
        for (let datecon of this.dateConditionArr) {
          if (datecon == 'between') {
            this.betweenDateFlag = true;
            this.form.addControl('startDate', newFormControl);
            this.form.addControl('endDate', newFormControl);
          }
        }
      }
    }



  }

  onDateChange(data) {
    if (data.key == 'between') {
      this.betweenDateFlag = true;
    }
    else {
      this.betweenDateFlag = false;
    }
    
  }
  getDynamicDDLValue(apiEndPoint, index: number, apiList) {
    if (apiEndPoint !== 'staticList') {
      this.advanceSearchService.getDynamicValue(apiEndPoint).subscribe(result => {
        if (result.dataList !== null) {
          this.formControlValue[index].dynamicFormData.valueArr = result.dataList;
        }
        if (result.partnerlist) {
          let partnerId = localStorage.getItem('partnerId')
          let isPartner = localStorage.getItem('partnerFlag')
          if(isPartner == 'false'){
            this.formControlValue[index].dynamicFormData.valueArr = result.partnerlist;
          }
          else{
            this.recentRemewalService.getManagablePartnerlistService().subscribe(result => {
              this.formControlValue[index].dynamicFormData.valueArr = result.dataList;
            })
          } 
        }
        if (result.staffUserlist) {
          this.formControlValue[index].dynamicFormData.valueArr = result.staffUserlist
        }
      });
    }
    if (apiEndPoint == 'staticList') {
      this.formControlValue[index].dynamicFormData.valueArr = [{ 'text': 'Online', 'value': 'online' },
      { 'text': 'Offline', 'value': 'offline' },
      { 'text': 'Inactive', 'value': 'inactive' }
      ]
    }
  }

  changeDDL(event,index) {
    this.formControlValue.forEach((ls)=>{
      if(ls.dynamicFormData.paramName == 'Olt' && !ls.dynamicFormData.paramValue){
        this.snmpEnabled = false;  
      }
    })    
    if (event.snmpEnabled == 'Yes') {
      this.snmpEnabled = true;
      const newFormControl = new FormControl();
      this.form.addControl('frame', newFormControl);
      this.form.addControl('slot', newFormControl);
      this.form.addControl('port', newFormControl);
      let obj = this.formControlValue
      for (let control of obj) {
        control.dynamicFormData.frameValue_filterconditions = null;
        control.dynamicFormData.slotValue_filterconditions = null;
        control.dynamicFormData.portValue_filterconditions = null;
      }
      }
    if (this.snmpEnabled && event.name) {
      this.advanceSearchService.getDetailsForDevice(event.name).subscribe(result => {
        this.frameList = result.data.frameList;
        this.slotList = result.data.slotList;
        this.portList = result.data.portList;
        this.frameFunction(this.frameList);
      });
    }
    if (event.type == "custStatus") {
      this.custStatus = true;
      this.custStatusValue = event.value;
    }
    

  }

  frameFunction(data) {
    for (let i = 0; i <= data; i++) {
      this.frameRange.push({ key: i, value: i });
    }
    

  }


  ngAfterViewInit() {
  }


  ngOnChanges() {
    if (!this.isOpened) {
      return;
    }
  }


  toggleSearchBar(state): void {
    this.toggleSearchEvent.emit(state);
    this.searchService.setSearchSettings(this.searchSettings);
  }

  onClickSearch() {
    let filters = new Array<SearchData>();
    
    this.formControlValue.forEach((ls) => {
      if (ls.dynamicFormData.paramName == 'Olt') {
        let filterObj = new SearchData();
        if(ls.dynamicFormData.paramValue != null){
          filterObj.filterColumn = 'frame';
          filterObj.filterValue = ls.dynamicFormData.frameValue_filterconditions;
          filterObj.filterOperator = this.paramValue_filterconditions.IS_EQUAL_TO;
          filters.push(filterObj);

          filterObj = new SearchData();
          filterObj.filterColumn = 'slot';
          filterObj.filterValue = ls.dynamicFormData.slotValue_filterconditions;
          filterObj.filterOperator = this.paramValue_filterconditions.IS_EQUAL_TO;
          filters.push(filterObj);
  
          filterObj = new SearchData();
          filterObj.filterColumn = 'port';
          filterObj.filterValue = ls.dynamicFormData.portValue_filterconditions;
          filterObj.filterOperator = this.paramValue_filterconditions.IS_EQUAL_TO;
          filters.push(filterObj);
        }
      }
      if ((ls.dynamicFormData.paramValue && this.paramValue_filterconditions.IS_EQUAL_TO)) {
        let filterObj = new SearchData();
        filterObj.filterColumn = ls.dynamicFormData.paramKey;
        
        // filterObj.filterDataType = ls.dynamicFormData.filterDataType;
        filterObj.filterValue = typeof(ls.dynamicFormData.paramValue) == 'string' ? ls.dynamicFormData.paramValue.trim() : ls.dynamicFormData.paramValue;
        filterObj.filterOperator = this.paramValue_filterconditions.IS_EQUAL_TO;
        if (ls.dynamicFormData.paramName == 'Expiry Date') {
          filterObj['startDate'] = this.datepipe.transform(ls.dynamicFormData.paramValue, 'yyyy-MM-dd');
          filterObj.filterValue = undefined;
        }
        filters.push(filterObj);
      }
    });
    let moduleName = this.formControlValue[0].moduleName;
    if(moduleName == 'Case'){
      if(window.location.href.includes('casesInternal')){
        localStorage.setItem(moduleName+'Internal', JSON.stringify(this.formControlValue))
        localStorage.setItem(moduleName+'Internal_filter', JSON.stringify(filters))
      }
      else{
        localStorage.setItem(moduleName, JSON.stringify(this.formControlValue))
        localStorage.setItem(moduleName+'_filter', JSON.stringify(filters))
      }
    }
    else{
      localStorage.setItem(moduleName, JSON.stringify(this.formControlValue))
        localStorage.setItem(moduleName+'_filter', JSON.stringify(filters))
    }

    
    this.filters = Object.assign([], filters)
    this.onApplyFilter.emit(this.filters);
    this.searchService.setSearchSettings(this.searchSettings);
  }


  clearFilter() {
    
    let obj = this.formControlValue
    for (let control of obj) {
      control.dynamicFormData.paramValue = null;
      // control.dynamicFormData.paramValue_filterconditions = null;
      control.dynamicFormData.frameValue_filterconditions = null;
      control.dynamicFormData.slotValue_filterconditions = null;
      control.dynamicFormData.portValue_filterconditions = null;
      control.dynamicFormData.startDateValue = null;
      control.dynamicFormData.endDateValue = null;
    }
    localStorage.removeItem(this.formControlValue[0].moduleName)
    localStorage.removeItem(this.formControlValue[0].moduleName+'_filter')
    this.onClearFilter.emit(null);
    this.searchService.setSearchSettings(this.searchSettings);
  }



  ngOnDestroy() {

  }

}


// <option * ngFor="let data of control.dynamicFormData.filterconditionsList"
// value = "{{data.value}}" > {{ data.key }}</option>
